import { useContext, useState } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { TemplateQuestionCategory } from "./TemplateQuestionCategory";
import { TemplateQuestion } from "../../api/models";
import { IIconProps, ISearchBoxStyles, SearchBox } from "@fluentui/react";


export function TemplateQuestionContainer() {
    const appStateContext = useContext(AppStateContext);
    const questions = appStateContext?.state.frontendSettings?.template_questions ?? [];
    const categories = [... new Set(questions?.map(q => q.categoryName))]; // distinct list
    const [filteredQuestions, setFilteredQuestions] = useState<TemplateQuestion[]>(questions);

    const filterIcon: IIconProps = { iconName: 'Search', style: { color: "rgb(50, 49, 48)" } }
    const searchStyles: ISearchBoxStyles = { root: { width: "80%", maxWidth: "300px", marginBottom: "20px", selectors: { ":after": { borderColor: "black" } } }, }

    const filterQuestions = (filterString: string | undefined) => {
        if (!filterString) {
            setFilteredQuestions(questions); 
            return;
        }
        setFilteredQuestions(questions.filter(x => x.shortDescription?.toLowerCase().includes(filterString) ?? x.question.toLowerCase().includes(filterString)));
    }

    return (
        <>
            <SearchBox styles={searchStyles} onClear={() => setFilteredQuestions(questions)} iconProps={filterIcon} placeholder="Suche" onChange={(_, filterString) => filterQuestions(filterString)} underlined={true} />
            <>
                {categories.map(category =>
                    <TemplateQuestionCategory hidden={filteredQuestions.length != questions.length} categoryName={category} questions={filteredQuestions?.filter(q => q.categoryName === category)} />
                )}
            </>
        </>
    )
}