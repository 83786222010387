import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";
import { Configuration, PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { overloadFetch } from "./api/fetch-interceptor";

initializeIcons();

const msalConfig: Configuration = {
    auth: {
        clientId: "b060925d-95db-40bf-b936-ac121639ca48",
        authority: "https://login.microsoftonline.com/fdb7e048-6b4d-492a-a6c2-0b6457ff51ee",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    }
};

export const msalApp = new PublicClientApplication(msalConfig);

await msalApp.initialize();

if (!msalApp.getActiveAccount() && msalApp.getAllAccounts().length > 0) msalApp.setActiveAccount(msalApp.getAllAccounts()[0]);

msalApp.addEventCallback((event) => {
    const payload = event.payload as AuthenticationResult;
    if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
        const account = payload.account;
        msalApp.setActiveAccount(account);
    }
});

overloadFetch();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

export default function App() {
    return (
        <MsalProvider instance={msalApp}>
            <AppStateProvider>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Chat />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </HashRouter>
            </AppStateProvider>
        </MsalProvider>
    );
}
