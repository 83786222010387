import { CommandBarButton, DefaultButton, IButtonProps, IButtonStyles } from "@fluentui/react";

import styles from './Button.module.css';
import { CosmosDBStatus } from "../../api/models";
import { AppState, Action } from "../../state/AppProvider";

interface ButtonProps extends IButtonProps {
  onClick?: () => void;
  link?: string;
  text: string | undefined;
  isScreenSmall: boolean;
}

export const ShareButton: React.FC<ButtonProps> = ({ onClick, text }) => {

  return (
    <CommandBarButton
      className={styles.shareButtonRoot}
      iconProps={{ iconName: 'Share' }}
      onClick={onClick}
      text={text}
    />
  )
}

export const HistoryButton: React.FC<ButtonProps> = ({ onClick, text, isScreenSmall }) => {
  return (
    <DefaultButton
      className={styles.navBarButtonRoot}
      text={isScreenSmall ? undefined : text}
      iconProps={{ iconName: 'History' }}
      onClick={onClick}
      title="Chatverlauf öffnen" 
    />
  )
}

export const HelpButton: React.FC<ButtonProps> = ({ link, text, isScreenSmall }) => {
  return (
    <DefaultButton
      className={styles.navBarButtonRoot}
      text={isScreenSmall ? undefined : text}
      iconProps={{ iconName: 'Help' }}
      href={link}
      target="_blank"
      title="Benutzerdokumentation"
    />
  )
}

const commandBarButtonStyles: IButtonStyles = {
  icon: {
    color: '#FFFFFF',
  },
  iconDisabled: {
    color: "#BDBDBD !important",
  },
  root: {
    color: '#FFFFFF',
    background: "#ff724a",
  },
  rootDisabled: {
    background: "#F0F0F0"
  },
  rootHovered: {
    background: "#FF501E"
  },
  iconHovered: {
    color: '#FFFFFF',
  }
};

export const DeleteChatButton = ({ appStateContext, deleteChat, newChat, disabled }: { appStateContext: { state: AppState, dispatch: React.Dispatch<Action> } | undefined, deleteChat: () => void, newChat: () => void, disabled: boolean }) => {
  return (
    <CommandBarButton
      role="button"
      styles={commandBarButtonStyles}
      className={styles.clearChatBroom}
      iconProps={{ iconName: 'Delete' }}
      onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? deleteChat : newChat}
      disabled={disabled}
      aria-label="clear chat button"
      title="Chat löschen" />
  );
}

export const NewChatButton = ({ newChat, disabled }: { newChat: () => void, disabled: boolean }) => {
  return (
    <CommandBarButton
      role="button"
      styles={commandBarButtonStyles}
      className={styles.newChatIcon}
      iconProps={{ iconName: 'Add' }}
      onClick={newChat}
      disabled={disabled}
      aria-label="start a new chat button"
      title="Neue Unterhaltung erstellen" />
  );
}

export const NewChatButtonSmall = ({ newChat }: { newChat: () => void }) => {
  return (
    <CommandBarButton
      role="button"
      className={styles.navBarButtonRoot}
      iconProps={{ iconName: 'Add', style: { color: "rgb(50, 49, 48)"} }}
      onClick={newChat}
      aria-label="start a new chat button"
      title="Neue Unterhaltung erstellen" />
  );
}