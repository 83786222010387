import { CommandBarButton, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, ICommandBarStyles, IContextualMenuItem, IStackStyles, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import React, { useContext } from "react";
import { historyDeleteAll } from "../../api/api";
import { ChatHistoryLoadingState } from "../../api/models";
import { AppStateContext } from "../../state/AppProvider";
import ChatHistoryList from "./ChatHistoryList";
import styles from "./ChatHistoryPanel.module.css";

interface ChatHistoryPanelProps {
}

export enum ChatHistoryPanelTabs {
    History = "History"
}



const commandBarButtonStyle: Partial<IStackStyles> = { root: { height: '50px' } };

export function ChatHistoryPanel(props: ChatHistoryPanelProps) {
    const appStateContext = useContext(AppStateContext)
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const [hideClearAllDialog, { toggle: toggleClearAllDialog }] = useBoolean(true);
    const [clearing, setClearing] = React.useState(false);
    const [clearingError, setClearingError] = React.useState(false);

    const clearAllDialogContentProps = {
        type: DialogType.close,
        title: !clearingError ? 'Sind Sie sich sicher, dass Sie alle Chatverläufe löschen möchten?' : 'Fehler beim Löschen aller Chatverläufe',
        closeButtonAriaLabel: 'Schließen',
        subText: !clearingError ? 'Alle Chatverläufe werden dauerhaft gelöscht.' : 'Bitte versuchen Sie es noch einmal. Sollte das Problem weiterhin bestehen, bitte den Administrator der Website kontaktieren.',
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const menuItems: IContextualMenuItem[] = [
        { key: 'clearAll', text: 'Alle Chatverläufe löschen', iconProps: { iconName: 'Delete', style: {color: "rgb(50, 49, 48)"} } },
    ];

    const handleHistoryClick = () => { appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' }) };

    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault(); // don't navigate
        setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

    const onClearAllChatHistory = async () => {
        setClearing(true)
        let response = await historyDeleteAll()
        if (!response.ok) setClearingError(true)
        else {
            appStateContext?.dispatch({ type: 'DELETE_CHAT_HISTORY' })
            toggleClearAllDialog();
        }
        setClearing(false);
        appStateContext?.dispatch({ type: 'NEW_CHAT', payload: false });
    }

    const onHideClearAllDialog = () => {
        toggleClearAllDialog()
        setTimeout(() => { setClearingError(false); }, 2000);
    }

    React.useEffect(() => { }, [appStateContext?.state.chatHistory, clearingError]);

    return (
        <section className={styles.container} data-is-scrollable aria-label={"chat history panel"}>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' wrap aria-label="chat history header">
                <StackItem>
                    <Text role="heading" aria-level={2} className={styles.heading}>Chatverläufe</Text>
                </StackItem>
                <Stack verticalAlign="start">
                    <Stack horizontal styles={commandBarButtonStyle}>
                        <CommandBarButton
                            iconProps={{ iconName: 'More', style: {color: "rgb(50, 49, 48)"} }}
                            title={"Alle Chatverläufe löschen"}
                            onClick={onShowContextualMenu}
                            aria-label={"clear all chat history"}
                            className={styles.commandBarStyle}
                            role="button"
                            id="moreButton" />
                        <ContextualMenu
                            items={menuItems}
                            hidden={!showContextualMenu}
                            target={"#moreButton"}
                            onItemClick={toggleClearAllDialog}
                            onDismiss={onHideContextualMenu} />
                        <CommandBarButton
                            iconProps={{ iconName: 'Cancel', style: {color: "rgb(50, 49, 48)"} }}
                            title={"Schließen"}
                            onClick={handleHistoryClick}
                            aria-label={"hide button"}
                            className={styles.commandBarStyle}
                            role="button" />
                    </Stack>
                </Stack>
            </Stack>
            <Stack aria-label="chat history panel content" className={styles.panelContent}>
                <Stack>
                    {(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Success && appStateContext?.state.isCosmosDBAvailable.cosmosDB) && <ChatHistoryList />}
                    {(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && appStateContext?.state.isCosmosDBAvailable) && <>
                        <Stack>
                            <Stack horizontalAlign='center' verticalAlign='center' className={styles.stack}>
                                <StackItem>
                                    <Text className={styles.textLarge}>
                                        {appStateContext?.state.isCosmosDBAvailable?.status && <span>{appStateContext?.state.isCosmosDBAvailable?.status}</span>}
                                        {!appStateContext?.state.isCosmosDBAvailable?.status && <span>Error loading chat history</span>}

                                    </Text>
                                </StackItem>
                                <StackItem>
                                    <Text className={styles.textMedium}>
                                        <span>Chat history can't be saved at this time</span>
                                    </Text>
                                </StackItem>
                            </Stack>
                        </Stack>
                    </>}
                    {appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading && <>
                        <Stack>
                            <Stack horizontal horizontalAlign='center' verticalAlign='center' className={styles.stack}>
                                <StackItem className={styles.stackItem}>
                                    <Spinner className={styles.spinner} size={SpinnerSize.medium} />
                                </StackItem>
                                <StackItem>
                                    <Text className={styles.textMedium}>
                                        <span className={styles.loadingChatHistory}>Loading chat history</span>
                                    </Text>
                                </StackItem>
                            </Stack>
                        </Stack>
                    </>}
                </Stack>
            </Stack>
            <Dialog
                hidden={hideClearAllDialog}
                onDismiss={clearing ? () => { } : onHideClearAllDialog}
                dialogContentProps={clearAllDialogContentProps}
                modalProps={modalProps}>
                <DialogFooter className={styles.popupFooter}>
                    {!clearingError && <DefaultButton onClick={onClearAllChatHistory} className={styles.footerButton} disabled={clearing} text="Alle löschen" />}
                    <DefaultButton onClick={onHideClearAllDialog} disabled={clearing} className={styles.footerButton} text={!clearingError ? "Abbrechen" : "Schließen"} />
                </DialogFooter>
            </Dialog>
        </section>
    );
}