import { ComboBox, DefaultButton, FocusTrapZone, IComboBox, IComboBoxOption, IComboBoxStyles, IconButton, Layer, Overlay, Popup, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { TemplateQuestion } from "../../constants/models";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./TemplateInputPopup.module.css";

interface Props {
    question: TemplateQuestion,
}

export function TemplateInputPopup({ question }: Props) {
    const [input, setInput] = useState<string | undefined>(undefined);
    const appStateContext = useContext(AppStateContext);
    const [prompt, setPrompt] = useState({ question: question.question }); // must be done with object, otherwise it does not rerender when necessary

    useEffect(() => {
        var replacedPrompt = question.question;
        if (question.options) question.options.forEach((v, i) => { replacedPrompt = replacedPrompt.replace(`{{${i}}}`, `*${v.key}*`); });
        setPrompt({question: replacedPrompt});
    }, []);
    
    const comboBoxStyles: Partial<IComboBoxStyles> =
    {
        input: { backgroundColor: "transparent" },
        root: { backgroundColor: "transparent", selectors: { ":after": { borderWidth: 0, borderRadius: 0 } } },
    };

    const getPosition = (string: string, subString: string, index: number) => string.split(subString, index).join(subString).length;

    function onChangeText(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v: string | undefined, placeholder: string, index: number) {
        const oldValue = (e.target as HTMLInputElement).lastChild?.textContent ?? prompt.question?.substring(getPosition(prompt.question, "*", index * 2 + 1) + 1, getPosition(prompt.question, "*", index * 2 + 2));
        if (v == undefined) return;
        if (oldValue && v == "") setPrompt({question: prompt.question.replace(`*${oldValue}*`, `**`,)});
        if (prompt.question.includes(`*${placeholder}*`)) setPrompt({question: prompt.question.replace(`*${placeholder}*`, `*${v}*`,)});
        else if (oldValue && prompt.question.includes(oldValue)) setPrompt({question: prompt.question.replace(`*${oldValue}*`, `*${v}*`,)});
        else if (oldValue == undefined) setPrompt({question: prompt.question.replace("**", `*${v}*`)});
    }

    function onChangeCombo(placeholder: string, option?: IComboBoxOption, index?: number, value?: string) {
        if (!option && !value) return;
        else if (!option && value == "") setPrompt({question: prompt.question.replace(`*${input}*`, `*${placeholder}*`,)});
        else if (!option && value) if (question.options && index !== undefined) question.options[index].value.push(value);
        if (value || option && value != "") {
            if (prompt.question.includes(`*${placeholder}*`)) setPrompt({question: prompt.question.replace(`*${placeholder}*`, `*${value}*`,)});
            else if (input && prompt.question.includes(input)) setPrompt({question: prompt.question.replace(`*${input}*`, `*${value}*`,)});
        }
        setInput(value);
    };

    function onKeyDownCombo(e: React.KeyboardEvent<IComboBox>, placeholder: string, index?: number) {
        const value = (e.target as HTMLInputElement).value;    
        if (value == "") setPrompt({question: prompt.question.replace(`*${input}*`, `*${placeholder}*`,)});
        else if (prompt.question.includes(`*${placeholder}*`)) setPrompt({question: prompt.question.replace(`*${placeholder}*`, `*${value}*`,)});
        else if (input && prompt.question.includes(input)) setPrompt({question: prompt.question.replace(`*${input}*`, `*${value}*`,)});
        setInput(value);
    }

    function TemplateInput(key: string, options: string[], index: number) {
        if (!options) {
            return (
                <div className={styles.multilineTextField}>
                    <TextField
                        multiline={true}
                        autoAdjustHeight={true}
                        className={styles.multilineTextField}
                        onChange={(e, v) => onChangeText(e, v, key, index)} />
                </div>)
        }
        if (options.length < 1) {
            return (<TextField onChange={(e, v) => onChangeText(e, v, key, index)} />)
        }
        else if (options) {
            return (
                <ComboBox
                    selectedKey={input}
                    onKeyUp={(e) => onKeyDownCombo(e, key, index)}
                    placeholder="bitte auswählen"
                    options={options.map((o) => { return { key: o, text: o } })}
                    styles={comboBoxStyles}
                    onChange={(_, o, i, v) => onChangeCombo(key, o, index, v,)}
                    allowFreeform={true}
                    autoComplete="off" />
            )
        }
        return (<div></div>);
    };

    function sendPrompt() {
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION_PROMPT', payload: prompt.question.replaceAll("*", "") });
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: undefined });
    }

    function definePrompt() {
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION_PROMPT', payload: prompt.question.replaceAll("*", "") });
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: question });
    }

    function closePopup() {
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: undefined });
    }

    return (
        <>
            <Layer>
                <Popup
                    className={styles.root}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={closePopup}
                    enableAriaHiddenSiblings={true}>
                    <Overlay onClick={closePopup} />
                    <FocusTrapZone>
                        <div role="document" className={styles.content}>
                            <div className={styles.popupHeader}>
                                <h3>Vorlage verwenden</h3>
                                <IconButton className={styles.closeButton} iconProps={{ iconName: 'Cancel' }} onClick={closePopup}></IconButton>
                            </div>
                            <div>
                                <p className={styles.promptPreview}>{(question.options && question.options?.filter(x => x.value == undefined).length < 1) ? prompt.question : question.shortDescription}</p>
                                {question.options && question.options.map((item, index) => (
                                    <>
                                        <h4>{item.key}</h4>
                                        {TemplateInput(item.key, item.value, index)}
                                    </>
                                ))}
                                <div className={styles.popupFooter}>
                                    <DefaultButton disabled={question.options && question.options?.filter(x => prompt.question.includes(`*${x.key}*`)).length > 0} className={styles.footerButton} onClick={definePrompt}>Weiter ergänzen</DefaultButton>
                                    <DefaultButton disabled={question.options && question.options?.filter(x => prompt.question.includes(`*${x.key}*`)).length > 0} className={styles.footerButton} onClick={sendPrompt}>Abschicken</DefaultButton>
                                </div>
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        </>
    );
}