// https://blog.logrocket.com/intercepting-javascript-fetch-api-requests-responses/

import { getBackendToken, getGraphToken } from "./auth";

export const overloadFetch = () => {
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
        let [resource, options] = args;

        if (resource.toString().startsWith("/history") || resource.toString().startsWith("/conversation")) {
            if (options === undefined) options = {};
            options.headers = {
                ...options.headers,
                "Authorization": `Bearer ${await getBackendToken()}`,
            };
        }

        if (resource.toString().startsWith("/history/generate") || resource.toString().startsWith("/conversation")) {
            if (options === undefined) options = {};
            options.headers = {
                ...options.headers,
                "X-Mum-Graph-Token": await getGraphToken(),
            };
        }

        const response = await originalFetch(resource, options);
        return response;
    }
}
