import { CommandBarButton, DefaultButton, Dialog, DialogFooter, DialogType, IButtonStyles, IconButton, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ErrorCircleRegular, SquareRegular } from "@fluentui/react-icons";
import DOMPurify from 'dompurify';
import { isEmpty } from "lodash-es";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useMediaQuery } from "react-responsive";
import uuid from 'react-uuid';
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm';
import { GeneratingAbortedBeforeAssistantResponseException, historyClear, historyGenerate, historyUpdate } from "../../api/api";
import { ChatHistoryLoadingState, ChatMessage, ChatResponse, Citation, Conversation, ConversationRequest, CosmosDBStatus, ErrorMessage, ToolMessageContent } from "../../api/models";
import MUM from "../../assets/mm_logo_farbe_web.png";
import { Answer } from "../../components/Answer/Answer";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { QuestionInput } from "../../components/QuestionInput/QuestionInput";
import { TemplateInputPopup } from "../../components/TemplateQuestions/TemplateInputPopup";
import { TemplateQuestionContainer } from "../../components/TemplateQuestions/TemplateQuestionContainer";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import { Action, AppState, AppStateContext } from "../../state/AppProvider";
import styles from "./Chat.module.css";
import { NewChatButton, DeleteChatButton } from "../../components/common/Button";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [deletingChat, setDeletingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const [showTemplateQuestions, setShowTemplateQuestions] = useState<boolean>(false);
    const [historyLoading, setHistoryLoading] = useState<boolean>(false);
    const [hideSwitchDialog, { toggle: toggleSwitchDialog }] = useBoolean(true);
    const isScreenSmall = useMediaQuery({ maxWidth: 600 });
    const isMobile = useMediaQuery({ maxWidth: 400 });

    const setIsLoading = (isLoading: boolean) => { appStateContext?.dispatch({ type: "IS_MESSAGE_GENERATING", payload: isLoading }); }

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const switchDialogContentProps = {
        type: DialogType.normal,
        title: 'Es wird eine Nachricht erzeugt.',
        closeButtonAriaLabel: 'Close',
        subText: 'Die Unterhaltung kann nicht gewechselt werden, solange eine Nachricht erzeugt wird. Brechen Sie den Vorgang ab oder warten Sie, bis die Nachricht vollständig ist.',
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]

    useEffect(() => {
        if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working
            && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
            && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail
            && hideErrorDialog) {
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Bitte den Administrator der Website kontaktieren.`
            setErrorMsg({
                title: "Chatverlauf ist nicht aktiviert.",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);

    useEffect(() => {
        if (appStateContext?.state.TemplateQuestionPrompt !== "" && !appStateContext?.state.TemplateQuestion) {
            const question: string = appStateContext?.state.TemplateQuestionPrompt ?? "";
            if (question !== "" && !question.includes("{{")) {
                if (appStateContext?.state.isCosmosDBAvailable.status == CosmosDBStatus.Working) {
                    setIsLoading(true);
                    setShowLoadingMessage(true);
                    makeApiRequestWithCosmosDB(question, undefined);
                }
                appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: undefined });
                appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION_PROMPT', payload: "" });
            }
        }
    }, [appStateContext?.state.TemplateQuestionPrompt]);

    useEffect(() => {
        if (appStateContext?.state.isMessageGenerating && !appStateContext.state.TemplateQuestionPrompt) {
            toggleSwitchDialog();
            return;
        }
        if (appStateContext?.state.newChat) {
            newChat();
            setShowTemplateQuestions(false);
            appStateContext?.dispatch({ type: 'NEW_CHAT', payload: false });
        }
    }, [appStateContext?.state.newChat]);

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => { setErrorMsg(null) }, 500);
    }

    useEffect(() => {
        setHistoryLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
    }, [appStateContext?.state.chatHistoryLoadingState])

    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent

            if (resultMessage.context) {
                toolMessage = {
                    id: uuid(),
                    role: TOOL,
                    content: resultMessage.context,
                    date: new Date().toISOString(),
                }
            }
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const makeApiRequestWithCosmosDB = async (question: string, conversationId?: string) => {
        const createConversationFromChatResponse = (response: ChatResponse, messages: ChatMessage[]): Conversation => {
            return {
                id: response.history_metadata.conversation_id,
                title: response.history_metadata.title,
                messages: messages,
                date: response.history_metadata.date
            }
        }

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let targetConversation: Conversation | undefined;
        if (conversationId) {
            targetConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if (!targetConversation) {
                console.error("Unterhaltung wurde nicht gefunden.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            }
        }

        //api call params set here (generate)
        let request: ConversationRequest = {
            messages: [],
            domainDataOnly: appStateContext?.state.useDomainDataOnly ?? true,
            filterCompanies: appStateContext?.state.selectedCompanies ?? [],
        };

        if (targetConversation) {
            targetConversation.messages.push(userMessage);
            request.messages = [...targetConversation.messages.filter((answer) => answer.role !== ERROR)];
        } else {
            request.messages = [userMessage].filter((answer) => answer.role !== ERROR);
            setMessages(request.messages)
        }

        let result = {} as ChatResponse;
        try {
            const response = conversationId ? await historyGenerate(request, abortController.signal, conversationId) : await historyGenerate(request, abortController.signal);
            if (!response?.ok) {
                const responseJson = await response.json();
                var errorResponseMessage = responseJson.error === undefined ? "Bitte versuchen Sie es noch einmal. Sollte das Problem weiterhin bestehen, bitte den Administrator der Website kontaktieren." : responseJson.error.message;

                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: `Es ist ein Fehler beim Generieren einer Antwort aufgetreten. Chatverlauf kann nicht gespeichert werden. \n\n${errorResponseMessage}`,
                    date: new Date().toISOString()
                };
                
                if (targetConversation) targetConversation.messages.push(errorChatMsg);
                else {
                    setMessages([...messages, userMessage, errorChatMsg])
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: targetConversation });
                setMessages([...targetConversation.messages]);
                return;
            }
            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        msg.id = result.id;
                                        msg.date = new Date().toISOString();
                                    })
                                    if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) setShowLoadingMessage(false);
                                    result.choices[0].messages.forEach((resultObj) => {
                                        processResultMessage(resultObj, userMessage, conversationId);
                                    })
                                }
                                runningText = "";
                            }
                            else if (result.error) throw Error(result.error);
                        } catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else console.log("Unvollständige Nachricht. Generiere Antwort...")
                        }
                    });
                }

                if (!targetConversation) targetConversation = createConversationFromChatResponse(result, [userMessage]);
                isEmpty(toolMessage) ?
                    targetConversation.messages.push(assistantMessage) :
                    targetConversation.messages.push(toolMessage, assistantMessage);

                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: targetConversation });
                isEmpty(toolMessage) ?
                    setMessages([...messages, assistantMessage]) :
                    setMessages([...messages, toolMessage, assistantMessage]);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = `Ein Fehler ist aufgetreten. ${errorResponseMessage}`;
                if (result.error?.message) errorMessage = result.error.message;
                else if (typeof result.error === "string") errorMessage = result.error;
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: ERROR,
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                if (targetConversation) targetConversation.messages.push(errorChatMsg);
                else {
                    if (!result.history_metadata) {
                        console.error("Fehler beim Abrufen der Daten.", result);
                        console.log("errorMessage", errorMessage)
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: errorMessage,
                            date: new Date().toISOString()
                        }
                        setMessages([...messages, userMessage, errorChatMsg])
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    targetConversation = createConversationFromChatResponse(result, [userMessage, errorChatMsg]);
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: targetConversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                if (e instanceof GeneratingAbortedBeforeAssistantResponseException) {
                    assistantMessage.id = uuid();
                    assistantMessage.content = "";
                    assistantMessage.date = new Date().toISOString();
                    assistantMessage.role = "assistant";
                }

                assistantMessage.content += "\n\nAchtung: Das Erzeugen der Antwort wurde abgebrochen - unvollständige Nachricht!";

                if (!targetConversation) targetConversation = createConversationFromChatResponse(result, [userMessage]);
                isEmpty(toolMessage) ?
                    targetConversation.messages.push(assistantMessage) :
                    targetConversation.messages.push(toolMessage, assistantMessage);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: targetConversation });
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }
        return abortController.abort();
    }

    const deleteChat = async () => {
        setDeletingChat(true)
        setShowTemplateQuestions(false);
        if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            let response = await historyClear(appStateContext?.state.currentChat.id)
            if (!response.ok) {
                setErrorMsg({
                    title: "Fehler beim Löschen der Unterhaltung.",
                    subtitle: "Bitte versuchen Sie es noch einmal. Sollte das Problem weiterhin bestehen, bitte den Administrator der Website kontaktieren.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'DELETE_CHAT_ENTRY', payload: appStateContext.state.currentChat.id });
                // appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([]);
            }
        }
        setDeletingChat(false);
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([]);
        setShowTemplateQuestions(false);
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done);
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useEffect(() => {
        setShowTemplateQuestions(false);
        if (appStateContext?.state.isMessageGenerating) {
            toggleSwitchDialog();
            return;
        }
        if (appStateContext?.state.currentChat) setMessages(appStateContext.state.currentChat.messages);
        else setMessages([]);
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            const response = await historyUpdate(messages, id)
            return response
        }

        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
                if (!appStateContext?.state.currentChat?.messages) {
                    console.error("Fehler beim Abrufen des Status der Unterhaltung.")
                    return
                }
                saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id).then((res) => {
                    if (!res.ok) {
                        let errorMessage = "Ein Fehler ist aufgetreten. Antworten können nicht gespeichert werden. Sollte das Problem weiterhin bestehen, bitte den Administrator der Website kontaktieren.";
                        let errorChatMsg: ChatMessage = {
                            id: uuid(),
                            role: ERROR,
                            content: errorMessage,
                            date: new Date().toISOString()
                        }
                        if (!appStateContext?.state.currentChat?.messages) {
                            let err: Error = {
                                ...new Error,
                                message: "Fehler beim Abrufen des Status der Unterhaltung."
                            }
                            throw err
                        }
                        setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                    }
                    return res as Response;
                })
                    .catch((err) => {
                        console.error("Error: ", err)
                        let errRes: Response = {
                            ...new Response,
                            ok: false,
                            status: 500,
                        }
                        return errRes;
                    })
            }
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation(citation);
        setIsCitationPanelOpen(true);
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) window.open(citation.url, "_blank");
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch { return []; }
        }
        return [];
    }

    const disabledButton = () => {
        const isLoading = appStateContext?.state.isMessageGenerating;
        return isLoading || (messages && messages.length === 0) || deletingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }

    return (
        <div className={styles.container} role="main">
            <Stack horizontal className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!messages || messages.length < 1 ? (
                        <Stack className={styles.chatEmptyState}>
                            {!showTemplateQuestions
                                ? <div className={styles.chatInformation}>
                                    <img
                                        src={ui?.chat_logo ? ui.chat_logo : MUM}
                                        className={styles.chatIcon}
                                        aria-hidden="true" />
                                    {!isMobile && (<h1 className={styles.chatEmptyStateTitle}>{ui?.chat_title}</h1>)}
                                    {!isScreenSmall && (<h2 className={styles.chatEmptyStateSubtitle}>{ui?.chat_description}</h2>)}
                                    <DefaultButton className={styles.showTemplateQuestionsButton} text={isScreenSmall ? "Vorlagen" : "Den mann&mouse Chatbot mit Vorlagen verwenden"} onClick={() => setShowTemplateQuestions(!showTemplateQuestions)} />
                                </div>
                                :
                                <div className={styles.TemplateQuestionsContainer}>
                                    <div className={styles.TemplateQuestionCategories}>
                                        <h4>Den mann&mouse Chatbot mit Vorlagen verwenden</h4>
                                        <TemplateQuestionContainer />
                                    </div>
                                    <DefaultButton className={styles.backButton} text="Zurück" onClick={() => setShowTemplateQuestions(!showTemplateQuestions)} />
                                </div>}
                        </Stack>
                    ) : (
                        <div className={styles.chatMessageStream} style={{ marginBottom: appStateContext?.state.isMessageGenerating ? "40px" : "0px" }} role="log">
                            {messages.map((answer, index) => (
                                <>
                                    {answer.role === "user" ? (
                                        <div className={styles.chatMessageUser} tabIndex={0}>
                                            <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                        </div>
                                    ) : (
                                        answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer: answer.content,
                                                    citations: parseCitationFromMessage(messages[index - 1]),
                                                    message_id: answer.id,
                                                    feedback: answer.feedback
                                                }}
                                                onCitationClicked={c => onShowCitation(c)} />
                                        </div> : answer.role === ERROR ? <div className={styles.chatMessageError}>
                                            <Stack horizontal className={styles.chatMessageErrorContent}>
                                                <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                <span>Error</span>
                                            </Stack>
                                            <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                        </div> : null
                                    )}
                                </>
                            ))}
                            {showLoadingMessage && (
                                <div className={styles.chatMessageGpt}>
                                    <Answer
                                        answer={{
                                            answer: "Antwort wird erzeugt...",
                                            citations: []
                                        }}
                                        onCitationClicked={() => null} />
                                </div>
                            )}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <Stack horizontal className={styles.chatInput}>
                        {appStateContext?.state.isMessageGenerating && (
                            <Stack
                                horizontal
                                className={styles.stopGeneratingContainer}
                                role="button"
                                aria-label="stop generating button"
                                tabIndex={0}
                                onClick={stopGenerating}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}>
                                <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                                <span className={styles.stopGeneratingText} aria-hidden="true">Erzeugen stoppen</span>
                            </Stack>
                        )}
                        {historyLoading && (
                            <Stack
                                horizontal
                                className={styles.historyLoadingContainer}
                                role="button"
                                aria-label="history loading button"
                                tabIndex={0}>
                                <span className={styles.historyLoadingText} aria-hidden="true">Chatverläufe werden geladen</span>
                            </Stack>
                        )}
                        {!isScreenSmall &&
                            <Stack>
                                {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && <NewChatButton newChat={newChat} disabled={disabledButton()} />}
                                <DeleteChatButton appStateContext={appStateContext} deleteChat={deleteChat} newChat={newChat} disabled={disabledButton()} />
                                <Dialog
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}>
                                </Dialog>
                            </Stack>}
                        <QuestionInput
                            newChat={newChat}
                            clearOnSend
                            placeholder="Frage eingeben..."
                            disabled={appStateContext?.state.isMessageGenerating ?? true}
                            onSend={(question, id) => {
                                appStateContext?.state.isCosmosDBAvailable?.cosmosDB ? makeApiRequestWithCosmosDB(question, id) : () => { }
                            }}
                            conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined} />
                    </Stack>
                    {isMobile && (<span className={styles.answerDisclaimer}>KI-generierter Inhalt könnte fehlerhaft sein.</span>)}
                </div>
                {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                    <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                        <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                            <span aria-label="Citations" className={styles.citationPanelHeader}>Zitate</span>
                            <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
                        </Stack>
                        <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
                        <div tabIndex={0}>
                            <ReactMarkdown
                                linkTarget="_blank"
                                className={styles.citationPanelContent}
                                children={DOMPurify.sanitize(activeCitation.content, { ALLOWED_TAGS: XSSAllowTags })}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]} />
                        </div>
                    </Stack.Item>
                )}
                {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel />}
                {appStateContext?.state.TemplateQuestion && (
                    <TemplateInputPopup question={appStateContext?.state.TemplateQuestion} />
                )}
                {(!hideSwitchDialog) && (
                    <Dialog
                        hidden={hideSwitchDialog}
                        onDismiss={toggleSwitchDialog}
                        dialogContentProps={switchDialogContentProps}
                        modalProps={modalProps}
                        minWidth={400}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={toggleSwitchDialog} text="Ok" />
                        </DialogFooter>
                    </Dialog>
                )}
            </Stack>
        </div>
    );
};

export default Chat;
