import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import Send from "../../assets/Send.svg";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./QuestionInput.module.css";
import { useMediaQuery } from "react-responsive";

interface Props {
    onSend: (question: string, id?: string) => void;
    newChat: () => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, newChat, disabled, placeholder, clearOnSend, conversationId }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const appStateContext = useContext(AppStateContext);
    const isScreenMedium = useMediaQuery( { maxWidth: 850 });

    useEffect(() => {
        if (appStateContext?.state.TemplateQuestion && appStateContext.state.TemplateQuestionPrompt) {
            const question: string = appStateContext?.state.TemplateQuestionPrompt ?? "";
            if (question !== "") {
                newChat();
                setQuestion(question);
            }
        }
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: undefined });
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION_PROMPT', payload: "" });
    }, [appStateContext?.state.TemplateQuestionPrompt]);

    useEffect(() => {
        if (appStateContext?.state.newChat) setQuestion("");
    }, [appStateContext?.state.newChat])

    const sendQuestion = () => {
        if (disabled || !question.trim()) return;
        if (conversationId) onSend(question, conversationId);
        else onSend(question);
        if (clearOnSend) setQuestion("");
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const onDomainDataToggle = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const onlyDomainData = ev.target.checked;
        appStateContext?.dispatch({ type: "UPDATE_USE_DOMAIN_DATA_ONLY", payload: onlyDomainData });
    };

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}/>
            <div className={styles.domainDataToggleContainer}>
                <label className={styles.domainDataToggleLabel} htmlFor="domainDataToggle">{isScreenMedium ? "Antworten beschränken" : "Antworten auf mann&mouse Daten beschränken"}</label>
                <input id="domainDataToggle" type="checkbox" onChange={onDomainDataToggle} checked={appStateContext?.state.useDomainDataOnly} />
            </div>
            <div className={styles.questionInputSendButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}>
                {sendQuestionDisabled ?
                    <SendRegular className={styles.questionInputSendButtonDisabled} />
                    : <img src={Send} className={styles.questionInputSendButton} />
                }
            </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
    );
};

export * from "./QuestionInput";
