import { useMsal } from "@azure/msal-react";
import { DefaultButton, FocusTrapZone, Layer, Overlay, Popup } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getProfileInfo } from "../../api/api";
import { getGraphToken } from "../../api/auth";
import { ProfileInfo } from "../../api/models";
import styles from "./ProfileDisplay.module.css";

const ProfileDisplay = () => {
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>({ displayName: "", photoURL: "" });
    const isScreenMedium = useMediaQuery({ maxWidth: 850 });
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const { instance } = useMsal();

    useEffect(() => {
        const fetchProfile = async () => {
            const token: string = await getGraphToken();
            const info: ProfileInfo = await getProfileInfo(token);
            setProfileInfo(info);
        }

        fetchProfile();
    }, []);

    const logout = () => { instance.logoutRedirect(); };

    const LogoutButton = () => {
        return (
            <DefaultButton
                text="Logout"
                iconProps={{ iconName: "SignOut" }}
                onClick={logout}
                className={styles.logoutButton}
            />
        );
    }

    // https://codepen.io/mihaeltomic/pen/PqxVaq
    return (
        <div className={styles.popoverWrapper}>
                <div className={styles.popoverTrigger}>
                    <img src={profileInfo.photoURL} className={styles.profileIcon} onClick={isScreenMedium ? () => setShowPopup(true) : () => {} } />
                    {!isScreenMedium && <span className={styles.profileName}>{profileInfo.displayName}</span>}
                </div>
            {!isScreenMedium ?
                (<div className={styles.popoverContent}>
                    <LogoutButton />
                </div>) :
                (<>
                    {showPopup && (<Layer>
                        <Popup
                            className={styles.popupRoot}
                            role="dialog"
                            aria-modal="true"
                            onDismiss={() => setShowPopup(false)}
                            enableAriaHiddenSiblings={true}>
                            <Overlay onClick={() => setShowPopup(false)} />
                            <FocusTrapZone>
                                <div role="document" className={styles.popup}>
                                    <h4>Möchten Sie sich abmelden?</h4>
                                    <div className={styles.popupContent}>
                                        <DefaultButton className={styles.popupButton} onClick={logout}>Ausloggen</DefaultButton>
                                        <DefaultButton className={styles.popupButton} onClick={() => setShowPopup(false)}>Abbrechen</DefaultButton>
                                    </div>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>)}
                </>)}
        </div >
    );
}

export default ProfileDisplay;