import { DefaultButton, Dialog, DialogFooter, DialogType, ITextField, IconButton, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Conversation } from '../../api/models';
import { AppStateContext } from '../../state/AppProvider';
import styles from "./ChatHistoryListItem.module.css";
import { historyDelete, historyRead, historyRename } from '../../api/api';
import { useMediaQuery } from 'react-responsive';

interface ChatHistoryListItemCellProps {
    item?: Conversation;
    onSelect: (item: Conversation | null) => void;
}

export const ChatHistoryListItemCell: React.FC<ChatHistoryListItemCellProps> = ({ item, onSelect,}) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [edit, setEdit] = useState(false);
    const [editTitle, setEditTitle] = useState("");
    const [hideDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(true);
    const [errorDelete, setErrorDelete] = useState(false);
    const [renameLoading, setRenameLoading] = useState(false);
    const [errorRename, setErrorRename] = useState<string | undefined>(undefined);
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    const textFieldRef = useRef<ITextField | null>(null);
    const isScreenSmall = useMediaQuery({ maxWidth: 600 });

    const appStateContext = React.useContext(AppStateContext)
    const isSelected = item?.id === appStateContext?.state.currentChat?.id;

    const deleteDialogContentProps = {
        type: DialogType.close,
        title: 'Sind Sie sich sicher, dass sie das ausgewählte Element löschen wollen?',
        closeButtonAriaLabel: 'Close',
        subText: 'Der Verlauf der Unterhaltung wird dauerhaft gelöscht werden.',
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    if (!item) return null;

    useEffect(() => {
        if (textFieldFocused && textFieldRef.current) {
            textFieldRef.current.focus();
            setTextFieldFocused(false);
        }
    }, [textFieldFocused]);

    useEffect(() => {
        if (appStateContext?.state.currentChat?.id !== item?.id) {
            setEdit(false);
            setEditTitle('');
        }
    }, [appStateContext?.state.currentChat?.id, item?.id]);

    const onDelete = async () => {
        let response = await historyDelete(item.id);
        if (!response.ok) {
            setErrorDelete(true)
            setTimeout(() => { setErrorDelete(false); }, 5000);
        } else appStateContext?.dispatch({ type: 'DELETE_CHAT_ENTRY', payload: item.id });
        toggleDeleteDialog();
    };

    const onEdit = () => {
        setEdit(true);
        setTextFieldFocused(true);
        setEditTitle(item?.title);
    };

    const handleSelectItem = async () => {
        onSelect(item);
        if (item.messages.length < 1) {
            item.messages = await historyRead(item.id)
                .then((res) => { return res; })
                .catch((err) => {
                    console.error("error fetching messages: ", err)
                    item.messages = [];
                    return [];
                });
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: item });
        if (isScreenSmall) appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' });
    }

    const truncatedTitle = (item?.title?.length > 25) ? `${item.title.substring(0, 25)} ...` : item.title;

    const handleSaveEdit = async (e: any) => {
        e.preventDefault();
        if (errorRename || renameLoading) return;
        if (editTitle == item.title) {
            setErrorRename("Fehler: Titel eingeben um fortzufahren.")
            setTimeout(() => {
                setErrorRename(undefined);
                setTextFieldFocused(true);
                if (textFieldRef.current) textFieldRef.current.focus();
            }, 5000);
            return;
        }
        setRenameLoading(true);
        let response = await historyRename(item.id, editTitle);
        if (!response.ok) {
            setErrorRename("Fehler: Element konnte nicht umbenannt werden.")
            setTimeout(() => {
                setTextFieldFocused(true);
                setErrorRename(undefined);
                if (textFieldRef.current) textFieldRef.current.focus();
            }, 5000);
        } else {
            setRenameLoading(false)
            setEdit(false)
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_TITLE', payload: { ...item, title: editTitle } as Conversation })
            setEditTitle("");
        }
    }

    const chatHistoryTitleOnChange = (e: any) => { setEditTitle(e.target.value); };

    const cancelEditTitle = () => {
        setEdit(false)
        setEditTitle("");
    }

    const handleKeyPressEdit = (e: any) => {
        if (e.key === "Enter") return handleSaveEdit(e);
        if (e.key === "Escape") {
            cancelEditTitle();
            return;
        }
    }

    return (
        <Stack
            key={item.id}
            tabIndex={0}
            aria-label='chat history item'
            className={styles.itemCell}
            onClick={() => handleSelectItem()}
            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleSelectItem() : null}
            verticalAlign='center'
            // horizontal
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            styles={{
                root: {
                    backgroundColor: isSelected ? '#e6e6e6' : 'transparent',
                }
            }}>
                {edit ? <>
                    <Stack.Item className={styles.fullWidth}>
                        <form aria-label='edit title form' onSubmit={(e) => handleSaveEdit(e)} className={styles.form}>
                            <Stack horizontal verticalAlign={'start'}>
                                <Stack.Item>
                                    <TextField
                                        componentRef={textFieldRef}
                                        autoFocus={textFieldFocused}
                                        value={editTitle}
                                        placeholder={item.title}
                                        onChange={chatHistoryTitleOnChange}
                                        onKeyDown={handleKeyPressEdit}
                                        // errorMessage={errorRename}
                                        disabled={errorRename ? true : false}
                                    />
                                </Stack.Item>
                                {editTitle && (<Stack.Item>
                                    <Stack aria-label='action button group' horizontal verticalAlign={'center'}>
                                        <IconButton disabled={errorRename !== undefined} onKeyDown={e => e.key === " " || e.key === 'Enter' ? handleSaveEdit(e) : null} onClick={(e) => handleSaveEdit(e)} aria-label='confirm new title' iconProps={{ iconName: 'CheckMark' }} className={styles.iconButtonSaveEdit} />
                                        <IconButton disabled={errorRename !== undefined} onKeyDown={e => e.key === " " || e.key === 'Enter' ? cancelEditTitle() : null} onClick={() => cancelEditTitle()} aria-label='cancel edit title' iconProps={{ iconName: 'Cancel' }} className={styles.iconButtonCancelEdit}/>
                                    </Stack>
                                </Stack.Item>)}
                            </Stack>
                            {errorRename && (
                                <Text role='alert' aria-label={errorRename} className={styles.renameError}>{errorRename}</Text>
                            )}
                        </form>
                    </Stack.Item>
                </> : <>
                    <Stack horizontal verticalAlign={'center'} className={styles.fullWidth}>
                        <div className={styles.chatTitle}>{isScreenSmall ? item.title : truncatedTitle}</div>
                        {(isSelected || isHovered) && <Stack horizontal horizontalAlign='end'>
                            <IconButton className={styles.itemButton} iconProps={{ iconName: 'Delete' }} title="Löschen"
                                onClick={ e => { e.stopPropagation(); toggleDeleteDialog(); }}
                                onKeyDown={ e => e.key === " " ? toggleDeleteDialog() : null}/>
                            <IconButton className={styles.itemButton} iconProps={{ iconName: 'Edit' }} title="Bearbeiten"
                                onClick={ e => { e.stopPropagation(); onEdit(); }}
                                onKeyDown={ e => e.key === " " ? onEdit() : null}/>
                        </Stack>}
                    </Stack>
                </>
                }
            {errorDelete && (
                <Text className={styles.errorText}>
                    Fehler: Element konnte nicht gelöscht werden.
                </Text>
            )}
            <Dialog
                hidden={hideDeleteDialog}
                onDismiss={toggleDeleteDialog}
                dialogContentProps={deleteDialogContentProps}
                modalProps={modalProps}>
                <DialogFooter className={styles.popupFooter}>
                    <DefaultButton onClick={onDelete} className={styles.footerButton} text="Löschen" />
                    <DefaultButton onClick={toggleDeleteDialog} className={styles.footerButton} text="Abbrechen" />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};