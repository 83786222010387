import { Checkbox, DefaultButton, Dialog, FontIcon, Stack, Text, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import DOMPurify from 'dompurify';
import { FormEvent, useContext, useEffect, useMemo, useState } from "react";
import { AppStateContext } from '../../state/AppProvider';
import styles from "./Answer.module.css";
import { parseAnswer } from "./AnswerParser";
import { ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub';
import { XSSAllowTags } from "../../constants/xssAllowTags";
import { historyMessageFeedback } from "../../api/api";
import { AskResponse, Citation, Feedback } from "../../api/models";
import { useMediaQuery } from "react-responsive";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({ answer, onCitationClicked }: Props) => {
    const initializeAnswerFeedback = (answer: AskResponse) => {
        if (answer.message_id == undefined) return undefined;
        if (answer.feedback == undefined) return undefined;
        if (answer.feedback.split(",").length > 1) return Feedback.Negative;
        if (Object.values(Feedback).includes(answer.feedback)) return answer.feedback;
        return Feedback.Neutral;
    }

    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [feedbackState, setFeedbackState] = useState(initializeAnswerFeedback(answer));
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [negativeFeedbackList, setNegativeFeedbackList] = useState<string[]>([]);
    const [negativeFeedbackComment, setNegativeFeedbackComment] = useState<string>();
    const appStateContext = useContext(AppStateContext);
    const FEEDBACK_ENABLED = appStateContext?.state.frontendSettings?.feedback_enabled && appStateContext?.state.isCosmosDBAvailable?.cosmosDB;
    const SANITIZE_ANSWER = appStateContext?.state.frontendSettings?.sanitize_answer;
    const isMobile = useMediaQuery({ maxWidth: 400 });

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    useEffect(() => {
        if (answer.message_id == undefined) return;

        let currentFeedbackState;
        if (appStateContext?.state.feedbackState && appStateContext?.state.feedbackState[answer.message_id]) {
            currentFeedbackState = appStateContext?.state.feedbackState[answer.message_id];
        } else currentFeedbackState = initializeAnswerFeedback(answer);
        setFeedbackState(currentFeedbackState)
    }, [appStateContext?.state.feedbackState, feedbackState, answer.message_id]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${part_i}`;
            }
            else citationFilename = `${citation.filepath} - Part ${part_i}`;
        }
        else if (citation.filepath && citation.reindex_id) citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        else citationFilename = `Zitat ${index}`;
        return citationFilename;
    }

    const onLikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        // Set or unset the thumbs up state
        if (feedbackState == Feedback.Positive) newFeedbackState = Feedback.Neutral;
        else newFeedbackState = Feedback.Positive;
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState } });
        setFeedbackState(newFeedbackState);

        // Update message feedback in db
        await historyMessageFeedback(answer.message_id, newFeedbackState);
    }

    const onDislikeResponseClicked = async () => {
        if (answer.message_id == undefined) return;

        let newFeedbackState = feedbackState;
        if (feedbackState === undefined || feedbackState === Feedback.Neutral || feedbackState === Feedback.Positive) {
            newFeedbackState = Feedback.Negative;
            setFeedbackState(newFeedbackState);
            setIsFeedbackDialogOpen(true);
        } else {
            // Reset negative feedback to neutral
            newFeedbackState = Feedback.Neutral;
            setFeedbackState(newFeedbackState);
            await historyMessageFeedback(answer.message_id, Feedback.Neutral);
        }
        appStateContext?.dispatch({ type: 'SET_FEEDBACK_STATE', payload: { answerId: answer.message_id, feedback: newFeedbackState } });
    }

    const updateFeedbackList = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (answer.message_id == undefined) return;
        let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

        let feedbackList = negativeFeedbackList.slice();
        if (checked) feedbackList.push(selectedFeedback);
        else feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
        setNegativeFeedbackList(feedbackList);
    };

    const onSubmitNegativeFeedback = async () => {
        if (answer.message_id == undefined) return;
        if (negativeFeedbackComment) negativeFeedbackList.push(negativeFeedbackComment);
        await historyMessageFeedback(answer.message_id, negativeFeedbackList.join(","));
        resetFeedbackDialog();
    }

    const resetFeedbackDialog = () => {
        setIsFeedbackDialogOpen(false);
        setNegativeFeedbackComment("");
        setNegativeFeedbackList([]);
    }

    const UnhelpfulFeedbackContent = () => {
        return (<>
            <div>Warum war diese Antwort nicht hilfreich?</div>
            <Stack tokens={{ childrenGap: 4 }}>
                <Checkbox label="Zitate fehlen" id={Feedback.MissingCitation} defaultChecked={negativeFeedbackList.includes(Feedback.MissingCitation)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Zitate sind falsch" id={Feedback.WrongCitation} defaultChecked={negativeFeedbackList.includes(Feedback.WrongCitation)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Die Antwort ist falsch" id={Feedback.WrongAnswer} defaultChecked={negativeFeedbackList.includes(Feedback.WrongAnswer)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Die Antwort ist nicht aus meinen Daten" id={Feedback.OutOfScope} defaultChecked={negativeFeedbackList.includes(Feedback.OutOfScope)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Ungenau oder irrelevant" id={Feedback.InaccurateOrIrrelevant} defaultChecked={negativeFeedbackList.includes(Feedback.InaccurateOrIrrelevant)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Unangemessener Inhalt" id={Feedback.Inappropriate} defaultChecked={negativeFeedbackList.includes(Feedback.Inappropriate)} onChange={updateFeedbackList}></Checkbox>
                <Checkbox label="Sonstiges" id={Feedback.OtherUnhelpful} defaultChecked={negativeFeedbackList.includes(Feedback.OtherUnhelpful)} onChange={updateFeedbackList}></Checkbox>
                <TextField label="Anmerkungen:" multiline rows={3} defaultValue={negativeFeedbackComment} onBlur={(v) => setNegativeFeedbackComment(v.target.value)}></TextField>
            </Stack>
        </>);
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item>
                    <Stack horizontal grow>
                        <Stack.Item grow>
                            <ReactMarkdown
                                linkTarget="_blank"
                                remarkPlugins={[remarkGfm, supersub]}
                                children={SANITIZE_ANSWER ? DOMPurify.sanitize(parsedAnswer.markdownFormatText, { ALLOWED_TAGS: XSSAllowTags }) : parsedAnswer.markdownFormatText}
                                className={styles.answerText}/>
                        </Stack.Item>
                        <Stack.Item className={styles.answerHeader}>
                            {FEEDBACK_ENABLED && answer.message_id !== undefined && <Stack horizontal horizontalAlign="space-between">
                                <ThumbLike20Filled
                                    aria-hidden="false"
                                    aria-label="Like this response"
                                    onClick={() => onLikeResponseClicked()}
                                    className={feedbackState === Feedback.Positive || appStateContext?.state.feedbackState[answer.message_id] === Feedback.Positive ? 
                                    styles.buttonGreen : styles.buttonGray}/>
                                <ThumbDislike20Filled
                                    aria-hidden="false"
                                    aria-label="Dislike this response"
                                    onClick={() => onDislikeResponseClicked()}
                                    className={(feedbackState !== Feedback.Positive && feedbackState !== Feedback.Neutral && feedbackState !== undefined) ?
                                        styles.buttonRed : styles.buttonGray}/>
                            </Stack>}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}>
                            <Stack className={styles.fullWidth}>
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button">
                                        <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " Referenzen" : "1 Referenz"}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}/>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    )}
                    {!isMobile && (<Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}>KI-generierter Inhalt könnte fehlerhaft sein.</span>
                    </Stack.Item>)}
                </Stack>
                {chevronIsExpanded &&
                    <div className={styles.answerCitations}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}>
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            <Dialog
                onDismiss={() => {
                    resetFeedbackDialog();
                    setFeedbackState(Feedback.Neutral);
                }}
                hidden={!isFeedbackDialogOpen}
                modalProps={{ className: styles.feedbackDialog }}
                dialogContentProps={{
                    title: "Feedback abschicken",
                    showCloseButton: true
                }}>
                <Stack tokens={{ childrenGap: 4 }}>
                    <div>Ihr Feedback hilft dabei, diesen Chatbot zu verbessern.</div>
                    <UnhelpfulFeedbackContent />
                    <div>Durch den Klick auf Abschicken wird Ihr Feedback für die Entwickler sichtbar.</div>
                    <DefaultButton disabled={negativeFeedbackList.length < 1} onClick={onSubmitNegativeFeedback}>Abschicken</DefaultButton>
                </Stack>
            </Dialog>
            </Stack>
        </>
    );
};

export * from "./Answer";