import React, { createContext, useReducer, ReactNode, useEffect, useState } from 'react';
import { appStateReducer } from './AppReducer';
import { historyList, historyEnsure, frontendSettings, docuLink } from '../api/api';
import { Conversation, ChatHistoryLoadingState, CosmosDBHealth, CosmosDBStatus, FrontendSettings, Feedback } from '../api/models';
import { useIsAuthenticated } from '@azure/msal-react';
import { TemplateQuestion } from '../constants/models';

export interface AppState {
    isChatHistoryOpen: boolean;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
    frontendSettings: FrontendSettings | null;
    feedbackState: { [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative; };
    useDomainDataOnly: boolean;
    selectedCompanies: string[];
    TemplateQuestion: TemplateQuestion | undefined;
    TemplateQuestionPrompt: string;
    newChat: boolean;
    isMessageGenerating: boolean;
    docuLink: string;
}

export type Action =
    | { type: 'TOGGLE_CHAT_HISTORY' }
    | { type: 'SET_COSMOSDB_STATUS', payload: CosmosDBHealth }
    | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState }
    | { type: 'UPDATE_CURRENT_CHAT', payload: Conversation | null }
    | { type: 'UPDATE_FILTERED_CHAT_HISTORY', payload: Conversation[] | null }
    | { type: 'UPDATE_CHAT_HISTORY', payload: Conversation } // API Call
    | { type: 'UPDATE_CHAT_TITLE', payload: Conversation } // API Call
    | { type: 'DELETE_CHAT_ENTRY', payload: string } // API Call
    | { type: 'DELETE_CHAT_HISTORY' }  // API Call
    | { type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: string }  // API Call
    | { type: 'FETCH_CHAT_HISTORY', payload: Conversation[] | null }  // API Call
    | { type: 'FETCH_FRONTEND_SETTINGS', payload: FrontendSettings | null }  // API Call
    | { type: 'SET_FEEDBACK_STATE'; payload: { answerId: string; feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral } }
    | { type: 'GET_FEEDBACK_STATE'; payload: string }
    | { type: 'UPDATE_USE_DOMAIN_DATA_ONLY'; payload: boolean }
    | { type: 'SET_SELECTED_COMPANIES'; payload: string[] }
    | { type: 'TEMPLATE_QUESTION'; payload: TemplateQuestion | undefined }
    | { type: 'TEMPLATE_QUESTION_PROMPT'; payload: string }
    | { type: 'NEW_CHAT'; payload: boolean }
    | { type: 'IS_MESSAGE_GENERATING'; payload: boolean }
    | { type: 'DOCU_LINK'; payload: string };

const initialState: AppState = {
    isChatHistoryOpen: false,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
    },
    frontendSettings: null,
    feedbackState: {},
    useDomainDataOnly: true,
    selectedCompanies: [],
    TemplateQuestion: undefined,
    TemplateQuestionPrompt: "",
    newChat: false,
    isMessageGenerating: false,
    docuLink: "",
};

export const AppStateContext = createContext<{ state: AppState; dispatch: React.Dispatch<Action>; } | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);
    const isAuthenticated: boolean = useIsAuthenticated();
    const [backendTokenScope, setBackendTokenScope] = useState<string>("");

    useEffect(() => {
        if (!isAuthenticated || backendTokenScope.length == 0) return;

        // Check for cosmosdb config and fetch initial data here
        const fetchChatHistory = async (offset = 0): Promise<Conversation[] | null> => {
            const result = await historyList(offset).then((response) => {
                if (response) dispatch({ type: 'FETCH_CHAT_HISTORY', payload: response });
                else dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                return response;
            }).catch((err) => {
                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                console.error("Es ist ein Fehler beim Abrufen der Daten aufgetreten.");
                return null;
            });
            return result;
        };

        const getHistoryEnsure = async () => {
            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Loading });
            historyEnsure().then((response) => {
                if (response?.cosmosDB) {
                    fetchChatHistory().then((res) => {
                        if (res) {
                            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                        } else {
                            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                        }
                    }).catch((err) => {
                        dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                        dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotWorking } });
                    })
                } else {
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                }
            }).catch((err) => {
                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: { cosmosDB: false, status: CosmosDBStatus.NotConfigured } });
            })
        }
        getHistoryEnsure();
    }, [isAuthenticated, backendTokenScope]);

    useEffect(() => {
        const getFrontendSettings = async () => {
            frontendSettings().then((settings: FrontendSettings | null) => {
                setBackendTokenScope(settings?.backend_token_scope ?? "");
                sessionStorage.setItem("backendTokenScope", settings?.backend_token_scope ?? "");
                dispatch({ type: 'FETCH_FRONTEND_SETTINGS', payload: settings });
            }).catch((err) => {
                console.error("Es ist ein Fehler beim Abrufen der Daten aufgetreten.");
            })
        }
        getFrontendSettings();
    }, []);

    useEffect(() => {
        const getDocuLink = async () => {
            docuLink().then((link: string) => {
                dispatch({ type: 'DOCU_LINK', payload: link });
            }).catch((err) => {
                console.error("Es ist ein Fehler beim Abrufen der Daten aufgetreten.");
            })
        }
        getDocuLink();
    }, []);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    );
};


