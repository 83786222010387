import { DefaultButton } from "@fluentui/react";
import { useContext } from "react";
import { TemplateQuestion } from "../../constants/models";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./TemplateQuestionItem.module.css";

interface Props {
    question: TemplateQuestion
}

export function TemplateQuestionItem({ question }: Props) {
    const appStateContext = useContext(AppStateContext);

    const handleClick = () => {
        appStateContext?.dispatch({ type: 'TEMPLATE_QUESTION', payload: question });
        appStateContext?.dispatch({ type: 'UPDATE_USE_DOMAIN_DATA_ONLY', payload: question.isDomainDataOnly ?? false });
    }

    const buildString = (): string => {
        if (question.shortDescription) return question.shortDescription + "...";
        if (!question.options) return question.question;
        var varString = question.question;
        question.options.forEach((v, i) => { varString = varString.replace(`{{${i}}}`, `*${v.key}*`); });
        return varString;
    }

    return (
        <DefaultButton
            text={buildString()}
            onClick={handleClick}
            iconProps={question.options ? { iconName: 'Edit' } : { iconName: '' }}
            className={styles.TemplateQuestionButton} />
    )
}