import { IconButton } from "@fluentui/react"
import { TemplateQuestionItem } from "./TemplateQuestionItem"
import { useEffect, useState } from "react";
import styles from "./TemplateQuestion.module.css";
import { TemplateQuestion } from "../../constants/models";

interface Props {
    categoryName: string,
    questions: TemplateQuestion[],
    hidden: boolean
}

export function TemplateQuestionCategory({ categoryName, questions, hidden }: Props) {
    const up: string = "ChevronUp";
    const down: string = "ChevronDown";
    const [currentIcon, setCurrentIcon] = useState<string>(hidden ? up : down);

    useEffect( () => setCurrentIcon(hidden ? up : down), [hidden]);

    return (
        <div className={styles.categoryAccordion}>
            <div className={styles.categoryAccordionTitle} onClick={() => setCurrentIcon(currentIcon == up ? down : up)}>
                <IconButton role="button" iconProps={{iconName: currentIcon}} className={styles.categoryAccordionIcon}/>
                {categoryName}
            </div>
            {currentIcon !== down && (<div className={styles.categoryAccordionContent}>
                {questions.map(question => 
                    <TemplateQuestionItem question={ question }/>
                )}
            </div>)}
        </div>
    )
}