import { ChatMessage, Conversation, ConversationRequest, CosmosDBHealth, CosmosDBStatus, FrontendSettings, ProfileInfo } from "./models";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages,
            domainDataOnly: options.domainDataOnly,
            filterCompanies: options.filterCompanies,
        }),
        signal: abortSignal
    });

    return response;
}

export const getProfileInfo = async (accessToken: string): Promise<ProfileInfo> => {
    const authHeader: string = `Bearer ${accessToken}`;

    const displayName: Promise<string> = fetch("https://graph.microsoft.com/v1.0/me/displayName/$value", {
        headers: {
            "Authorization": authHeader,
            "Content-Type": "application/json",
        }
    }).then(res => res.text());

    const photoURL: Promise<string> = fetch("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
        headers: {
            "Authorization": authHeader,
            "Content-Type": "image/jpg",
        }
    }).then(res => res.blob())
        .then(blob => URL.createObjectURL(blob));

    return Promise.all([displayName, photoURL]).then(([displayName, photoURL]) => {
        return {
            displayName,
            photoURL,
        };
    });
}

export const historyList = async (offset = 0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: [],
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null;
    })
    return response;
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        if (!res) return [];
        const payload = await res.json();
        let messages: ChatMessage[] = [];
        if (payload?.messages) {
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    feedback: msg.feedback ?? undefined
                }
                messages.push(message)
            });
        }
        return messages;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return [];
    });
    return response;
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body: object = { messages: options.messages, domainDataOnly: options.domainDataOnly, filterCompanies: options.filterCompanies };
    if (convId) body = { ...body, conversation_id: convId };

    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body),
        signal: abortSignal
    }).then((res) => {
        return res;
    }).catch(_ => {
        throw new GeneratingAbortedBeforeAssistantResponseException();
    });
    return response;
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    });
    return response;
}

export const historyDelete = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyDeleteAll = async (): Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyClear = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyRename = async (convId: string, title: string): Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    }).then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if (respJson.message) formattedResponse = CosmosDBStatus.Working
        else {
            if (res.status === 500) formattedResponse = CosmosDBStatus.NotWorking
            else if (res.status === 401) formattedResponse = CosmosDBStatus.InvalidCredentials
            else if (res.status === 422) formattedResponse = respJson.error
            else formattedResponse = CosmosDBStatus.NotConfigured
        }
        if (!res.ok) {
            return {
                cosmosDB: false,
                status: formattedResponse
            }
        } else {
            return {
                cosmosDB: true,
                status: formattedResponse
            }
        }
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err
        }
    })
    return response;
}

export const frontendSettings = async (): Promise<FrontendSettings | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const docuLink = async (): Promise<string> => {
    const response = await fetch("/docu_link", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        console.error("There was an issue logging feedback.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export class GeneratingAbortedBeforeAssistantResponseException extends Error { }

export * from "./api";