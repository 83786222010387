import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Dropdown, IDropdownOption, IDropdownStyles, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, Outlet } from "react-router-dom";
import { CosmosDBStatus } from "../../api/models";
import MUM from "../../assets/mm_logo_farbe_web.png";
import ProfileDisplay from "../../components/ProfileDisplay/ProfileDisplay";
import { HelpButton, HistoryButton, NewChatButtonSmall } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./Layout.module.css";
import { Warning20Regular } from "@fluentui/react-icons";

const Layout = () => {
    //const [selectedCompanies, setSelectedCompanies] = JSON.stringify([]);
    const isScreenMedium = useMediaQuery({ maxWidth: 850 });
    const isScreenSmall = useMediaQuery({ maxWidth: 600 });
    const [selectedCompanies, setSelectedCompanies] = useState<string[]>(JSON.parse(localStorage.getItem('selectedCompanies') ?? JSON.stringify([])));
    const appStateContext = useContext(AppStateContext);
    const ui = appStateContext?.state.frontendSettings?.ui;
    const filterCompanies = appStateContext?.state.frontendSettings?.filter_companies;
    const docuLink = appStateContext?.state.docuLink;

    const dropdownStyles: Partial<IDropdownStyles> = { title: { width: 300, border: 0, borderWidth: 0, borderRadius: "6px", height: "30px" } };
    const companyOptions: { key: string, text: string }[] | null = filterCompanies?.map(c => ({ key: c, text: c })) ?? null;

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    const onChangeCompanies = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) setSelectedCompanies(item.selected ? [...selectedCompanies, item.key as string] : selectedCompanies.filter(key => key !== item.key),);
    };

    useEffect(() => {
        appStateContext?.dispatch({ type: 'SET_SELECTED_COMPANIES', payload: selectedCompanies });
        localStorage.setItem('selectedCompanies', JSON.stringify(selectedCompanies));
    }, [selectedCompanies])

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center" onClick={() => appStateContext?.dispatch({ type: 'NEW_CHAT', payload: true })}>
                        <img
                            src={ui?.logo ? ui.chat_logo : MUM}
                            className={styles.headerIcon}
                            aria-hidden="true" />
                        {!isScreenSmall && (<Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>mann&mouse Chatbot</h1>
                        </Link>)}
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        {filterCompanies &&
                            <Dropdown
                                placeholder="Firmen auswählen"
                                selectedKeys={selectedCompanies}
                                onChange={onChangeCompanies}
                                multiSelect
                                options={companyOptions?.sort((a, b) => a.key.localeCompare(b.key)) ?? []}
                                styles={dropdownStyles}
                                className={styles.companyOptionsDropdown}
                            >
                            </Dropdown> 

                            ||

                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 3 }} title="Verbindung zur Datenbank schlug fehl.">
                                <Warning20Regular className={styles.noCompanyFilterWarning} />
                                <span className={styles.noCompanyFilterWarning}>Firmen-Filter nicht verfügbar</span>
                            </Stack>
                        }
                        {isScreenMedium && <NewChatButtonSmall newChat={() => appStateContext?.dispatch({ type: 'NEW_CHAT', payload: true })}></NewChatButtonSmall>}
                        <HelpButton link={docuLink} text="Hilfe" isScreenSmall={isScreenMedium}></HelpButton>
                        {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                            <HistoryButton onClick={handleHistoryClick} isScreenSmall={isScreenMedium} text={appStateContext?.state?.isChatHistoryOpen ? "Chatverlauf schließen" : "Chatverlauf anzeigen"} />
                        }
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            authenticationRequest={{ scopes: ["User.Read"] }}>
                            <ProfileDisplay />
                        </MsalAuthenticationTemplate>
                    </Stack>
                </Stack>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
