import { List, Separator, Stack } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Conversation } from '../../api/models';
import { GroupedChatHistory } from './ChatHistoryList';
import { ChatHistoryListItemCell } from './ChatHistoryListItem';
import styles from "./ChatHistoryListItemGroups.module.css";
import { formatMonth } from '../../constants/utils';

interface ChatHistoryListItemGroupsProps {
    groupedChatHistory: GroupedChatHistory[];
}

export const ChatHistoryListItemGroups: React.FC<ChatHistoryListItemGroupsProps> = ({ groupedChatHistory }) => {
    const observerTarget = useRef(null);
    const [, setSelectedItem] = React.useState<Conversation | null>(null);
    const [observerCounter, setObserverCounter] = useState(0);
    const firstRender = useRef(true);

    const handleSelectHistory = (item?: Conversation) => {
        if (item) setSelectedItem(item);
    }

    const onRenderCell = (item?: Conversation) => {
        return (<ChatHistoryListItemCell item={item} onSelect={() => handleSelectHistory(item)} />);
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
    }, [observerCounter]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => { 
                    if (entries[0].isIntersecting) 
                        setObserverCounter((observerCounter) => observerCounter += 1); 
                },
                { threshold: 1 }
        );

        if (observerTarget.current) observer.observe(observerTarget.current);

        return () => { if (observerTarget.current) observer.unobserve(observerTarget.current); };
    }, [observerTarget]);

    return (
        <div className={styles.listContainer} data-is-scrollable>
            {groupedChatHistory.map((group) => (
                group.entries.length > 0 && <Stack horizontalAlign="start" verticalAlign="center" key={group.month} className={styles.chatGroup} aria-label={`chat history group: ${group.month}`}>
                    <Stack aria-label={group.month} className={styles.chatMonth}>{formatMonth(group.month)}</Stack>
                    <List aria-label={`chat history list`} items={group.entries} onRenderCell={onRenderCell} className={styles.chatList} />
                    <div ref={observerTarget} />
                    <Separator className={styles.separator} />
                </Stack>
            ))}
        </div>
    );
};
