import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalApp } from "..";

export const getBackendToken = async (): Promise<string> => {
    const backendTokenScope = sessionStorage.getItem("backendTokenScope");
    if (backendTokenScope == null) return "";
    return getAccessToken([backendTokenScope]);
}

export const getGraphToken = async (): Promise<string> => { return getAccessToken(["User.Read"]); }

const getAccessToken = async (scopes: string[]): Promise<string> => {
    let authRes: AuthenticationResult | undefined;
    try { authRes = await msalApp.acquireTokenSilent({ scopes }); }
    catch (err) {
        if (err instanceof InteractionRequiredAuthError) msalApp.acquireTokenRedirect({ scopes });
        return "";
    }
    return authRes.accessToken;
}
